import React from 'react'
import { Container } from 'react-bootstrap'
import { Layout } from './Layout'
import { Section, SectionProps } from './Section'

export const PageLayout: React.FC<SectionProps> = ({ children, ...props }: SectionProps) => (
  <Layout>
    <Container as={Section} {...props}>
      {children}
    </Container>
  </Layout>
)
